
[data-amplify-authenticator] {
	--amplify-components-button-primary-background-color: #009688;
  }

  :root {
    --primary: #009688
  }

.App{
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.App-logo{
	width: 10rem;
}

.side-nav.wide .logo-wrapper.sn-ad-avatar-wrapper a img {
	max-width: 150px;
    margin-left: 30px;
}

.flyout {
	display:flex;
	flex-direction: column;
	min-height:100vh;
	justify-content: space-between;
}

.form-check > .rounded-circle {
    border-radius: unset!important;
    height: 30%!important;
}

body {
    background-color: #eee;
}

/* .card {
	box-shadow: none;
} */

.w-70 {
	width: 70%;
}

.custom-select:focus {
	box-shadow: none
}


.dropzone {
	border: 2px dashed #00796b !important;
	text-align: center
}

.dropzone:focus {
	outline: none
}

.md-form .prefix {
    top: 0.7rem;
    font-size: 1.2rem;
}

.mh-300 {
	min-height: 300px
}

.thick-border-success {
    border: 2px solid #00c851!important;;
}

.toDateBox > .form-check {
	width: 100%
}
.actionButtons > .btn {
	padding: 0px;
}

.side-nav {
	background-image: url(../src/img/sideNavBackground.png);
	background-color: #383c40;
}

.side-nav .logo-wrapper {
	border-bottom: 0px!important;
}

.side-nav ul li {
    padding: 0.15rem;
}

.side-nav .collapsible a {
	color: #c1c1c1;
	font-size: 0.85rem;
}
.side-nav .collapsible a i {
    width: 20px;
}

.side-nav .active .fa-angle-down.rotate-icon {
    margin-right: 2rem;
}

.pagination.pg-teal .page-item.active .page-link {
    background-color: #009688;
}

.w-100px {
	min-width: 100px;
}

.w-200 {
	min-width: 200px;
}

.mt--3 {
    margin-top:  -1.5rem!important;
}

.nowrap {
	flex-wrap: nowrap!important;
}

.spinner-small {
	width: 1rem!important;
	height: 1rem!important;
    margin-left: 1rem!important;
    margin-right: 1rem!important; 
}

.modalTeal .modal-header {
	background-color: #009688 !important;
	color: #fff;
}

.modalTeal .modal-header .close {
	color: #fff !important;
}

.ml-50 {
	margin-left: 50%;
}

.date-select-label-sm label {
	font-size: .8rem;
}

@media (min-width: 1450px) {
	.modal-xl {
		max-width: 1400px;
	}
}

.checkbox-white [type="checkbox"]:checked+label:before {
    border-color: transparent #ffffff #ffffff transparent !important;
}

.form-check-input[type="checkbox"]:checked+label:before {
    border-color: transparent #009688 #009688 transparent;
}

.md-form input:not([type]):focus:not([readonly]), .md-form input[type="text"]:not(.browser-default):focus:not([readonly]), .md-form input[type="password"]:not(.browser-default):focus:not([readonly]), .md-form input[type="email"]:not(.browser-default):focus:not([readonly]), .md-form input[type="url"]:not(.browser-default):focus:not([readonly]), .md-form input[type="time"]:not(.browser-default):focus:not([readonly]), .md-form input[type="date"]:not(.browser-default):focus:not([readonly]), .md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]), .md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly]), .md-form input[type="tel"]:not(.browser-default):focus:not([readonly]), .md-form input[type="number"]:not(.browser-default):focus:not([readonly]), .md-form input[type="search"]:not(.browser-default):focus:not([readonly]), .md-form input[type="phone"]:not(.browser-default):focus:not([readonly]), .md-form input[type="search-md"]:focus:not([readonly]), .md-form textarea.md-textarea:focus:not([readonly]) {
    border-bottom: 1px solid #009688;
    box-shadow: 0 1px 0 0 #009688;
}
.md-form input:not([type]):focus:not([readonly])+label, .md-form input[type="text"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="password"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="email"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="url"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="time"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="date"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="datetime"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="tel"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="number"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="search"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="phone"]:not(.browser-default):focus:not([readonly])+label, .md-form input[type="search-md"]:focus:not([readonly])+label, .md-form textarea.md-textarea:focus:not([readonly])+label {
    color: #009688;
}
.btn-default {
    background-color: #009688 !important;
}
.dropdown .dropdown-menu .dropdown-item:hover, .dropdown .dropdown-menu .dropdown-item:active, .dropup .dropdown-menu .dropdown-item:hover, .dropup .dropdown-menu .dropdown-item:active, .dropleft .dropdown-menu .dropdown-item:hover, .dropleft .dropdown-menu .dropdown-item:active, .dropright .dropdown-menu .dropdown-item:hover, .dropright .dropdown-menu .dropdown-item:active {
    background-color: #009688;
}
a:hover {
    color: #009688;
}
.navbar a:hover {
    color: #fff;
}
.dropdown-content li>a, .dropdown-content li>span {
    color: #009688;
}
.checkbox-white [type="checkbox"]+label:before {
    border: 2px solid #ffffff;
}
.checkbox-white {
    height: 22px;
}

.teal-grey-text {
    color: #618784 !important;
}
.row-0 {
	padding: 0px!important;
    border-top: 0px!important;
}


.red-skin .teal {
    background-color: #a60000 !important;
}

.red-skin .text-default {
    color: #a60000 !important;
}
.red-skin .gradient {
    background: #a60000;
    background: linear-gradient(135deg, #a60000 0%, #a60000 100%)
}

.red-skin .primary-color,.red-skin ul.stepper li.active a .circle,ul.stepper li.active a .red-skin .circle,.red-skin ul.stepper li.completed a .circle,ul.stepper li.completed a .red-skin .circle {
    background-color: #a60000 !important
}

/* .red-skin .navbar {
    color: #a60000;
    background-color: #a60000
} */

/* .red-skin .navbar .navbar-nav .nav-item .dropdown-menu a {
    color: #000
}

.red-skin .navbar .navbar-nav .nav-item .dropdown-menu a:hover,.red-skin .navbar .navbar-nav .nav-item .dropdown-menu a:focus,.red-skin .navbar .navbar-nav .nav-item .dropdown-menu a:active {
    background-color: #f2f2f2
}

.red-skin .navbar.double-nav a {
    color: #a60000
}

.red-skin .navbar form .md-form .form-control {
    font-weight: 300;
    color: #a60000
}

.red-skin .navbar form .md-form .form-control::placeholder {
    color: #a60000
} */

.red-skin .page-footer {
    background-color: #a60000
}

/* .red-skin .side-nav {
    background-color: #a60000
} */

/* .red-skin .side-nav .logo-wrapper>div {
    background-color: transparent !important
}

.red-skin .side-nav .sn-avatar-wrapper img {
    border: 3px solid #d9d9d9
}

.red-skin .side-nav .social {
    border-bottom: 1px solid rgba(153,153,153,0.3)
}

.red-skin .side-nav .social a:hover .fas,.red-skin .side-nav .social a:hover .fab,.red-skin .side-nav .social a:hover .far {
    color: #a60000 !important;
    transition: all 0.3s linear
}

.red-skin .side-nav .collapsible li {
    background-color: transparent
}

.red-skin .side-nav .collapsible li .collapsible-header {
    color: #a60000;
    transition: all 0.3s linear
}

.red-skin .side-nav .collapsible li .collapsible-header.active {
    background-color: #a60000
}

.red-skin .side-nav .collapsible li .collapsible-header:hover {
    background-color: #a60000
}

.red-skin .side-nav .collapsible li .collapsible-body a {
    color: #a60000
}

.red-skin .side-nav .collapsible li .collapsible-body a:hover,.red-skin .side-nav .collapsible li .collapsible-body a.active,.red-skin .side-nav .collapsible li .collapsible-body a:active {
    color: #a60000
}

.red-skin .side-nav .collapsible li .collapsible-body a .fas,.red-skin .side-nav .collapsible li .collapsible-body a .fab,.red-skin .side-nav .collapsible li .collapsible-body a .far {
    color: #a60000
}

.red-skin .side-nav .collapsible li a:not(.collapsible-header) {
    color: #a60000;
    transition: all 0.3s linear
}

.red-skin .side-nav .collapsible li a:not(.collapsible-header):hover,.red-skin .side-nav .collapsible li a:not(.collapsible-header).active,.red-skin .side-nav .collapsible li a:not(.collapsible-header):active {
    color: #a60000 !important
}

.red-skin .side-nav .sidenav-bg:after,.red-skin .side-nav .sidenav-bg.mask-strong:after {
    background: #a60000
}

.red-skin .side-nav .sidenav-bg.mask-light:after {
    background: #a60000
}

.red-skin .side-nav .sidenav-bg.mask-slight:after {
    background: #a60000
} */

.red-skin .btn-primary {
    color: #000;
    background-color: #a60000 !important
}

.red-skin .btn-primary:hover {
    color: #000;
    background-color: #a60000
}

.red-skin .btn-primary:focus,.red-skin .btn-primary.focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15)
}

.red-skin .btn-primary:focus,.red-skin .btn-primary:active,.red-skin .btn-primary.active {
    background-color: #ccc
}

.red-skin .btn-primary.dropdown-toggle {
    background-color: #a60000 !important
}

.red-skin .btn-primary.dropdown-toggle:hover,.red-skin .btn-primary.dropdown-toggle:focus {
    background-color: #a60000 !important
}

.red-skin .btn-primary:not([disabled]):not(.disabled):active,.red-skin .btn-primary:not([disabled]):not(.disabled).active,.show>.red-skin .btn-primary.dropdown-toggle {
    background-color: #ccc !important;
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15)
}

.red-skin .btn-primary:not([disabled]):not(.disabled):active:focus,.red-skin .btn-primary:not([disabled]):not(.disabled).active:focus,.show>.red-skin .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15)
}

.red-skin .primary-ic {
    color: #a60000 !important
}

.red-skin .primary-ic:hover,.red-skin .primary-ic:focus {
    color: #a60000
}

.red-skin a.btn:not([href]):not([tabindex]),.red-skin a.btn:not([href]):not([tabindex]):focus,.red-skin a.btn:not([href]):not([tabindex]):hover {
    color: #000
}

.red-skin table.table a.btn.btn-primary {
    color: #000
}

.red-skin .btn-secondary {
    color: #000;
    background-color: #a60000 !important
}

.red-skin .btn-secondary:hover {
    color: #000;
    background-color: #a60000
}

.red-skin .btn-secondary:focus,.red-skin .btn-secondary.focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15)
}

.red-skin .btn-secondary:focus,.red-skin .btn-secondary:active,.red-skin .btn-secondary.active {
    background-color: #ccc
}

.red-skin .btn-secondary.dropdown-toggle {
    background-color: #a60000 !important
}

.red-skin .btn-secondary.dropdown-toggle:hover,.red-skin .btn-secondary.dropdown-toggle:focus {
    background-color: #a60000 !important
}

.red-skin .btn-secondary:not([disabled]):not(.disabled):active,.red-skin .btn-secondary:not([disabled]):not(.disabled).active,.show>.red-skin .btn-secondary.dropdown-toggle {
    background-color: #ccc !important;
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15)
}

.red-skin .btn-secondary:not([disabled]):not(.disabled):active:focus,.red-skin .btn-secondary:not([disabled]):not(.disabled).active:focus,.show>.red-skin .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15)
}

.red-skin .secondary-ic {
    color: #a60000 !important
}

.red-skin .secondary-ic:hover,.red-skin .secondary-ic:focus {
    color: #a60000
}

.red-skin a.btn:not([href]):not([tabindex]),.red-skin a.btn:not([href]):not([tabindex]):focus,.red-skin a.btn:not([href]):not([tabindex]):hover {
    color: #000
}

.red-skin table.table a.btn.btn-secondary {
    color: #000
}

.red-skin .btn-default {
    color: #000;
    background-color: #a60000 !important
}

.red-skin .btn-default:hover {
    color: #000;
    background-color: #a60000
}

.red-skin .btn-default:focus,.red-skin .btn-default.focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15)
}

.red-skin .btn-default:focus,.red-skin .btn-default:active,.red-skin .btn-default.active {
    background-color: #ccc
}

.red-skin .btn-default.dropdown-toggle {
    background-color: #a60000 !important
}

.red-skin .btn-default.dropdown-toggle:hover,.red-skin .btn-default.dropdown-toggle:focus {
    background-color: #a60000 !important
}

.red-skin .btn-default:not([disabled]):not(.disabled):active,.red-skin .btn-default:not([disabled]):not(.disabled).active,.show>.red-skin .btn-default.dropdown-toggle {
    background-color: #ccc !important;
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15)
}

.red-skin .btn-default:not([disabled]):not(.disabled):active:focus,.red-skin .btn-default:not([disabled]):not(.disabled).active:focus,.show>.red-skin .btn-default.dropdown-toggle:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15)
}

.red-skin .default-ic {
    color: #a60000 !important
}

.red-skin .default-ic:hover,.red-skin .default-ic:focus {
    color: #a60000
}

.red-skin a.btn:not([href]):not([tabindex]),.red-skin a.btn:not([href]):not([tabindex]):focus,.red-skin a.btn:not([href]):not([tabindex]):hover {
    color: #000
}

.red-skin table.table a.btn.btn-default {
    color: #000
}

.red-skin .btn-outline-primary {
    color: #a60000 !important;
    background-color: transparent !important;
    border: 2px solid #a60000 !important
}

.red-skin .btn-outline-primary:hover,.red-skin .btn-outline-primary:focus,.red-skin .btn-outline-primary:active,.red-skin .btn-outline-primary:active:focus,.red-skin .btn-outline-primary.active {
    color: #a60000 !important;
    background-color: transparent !important;
    border-color: #a60000 !important
}

.red-skin .btn-outline-primary:not([disabled]):not(.disabled):active,.red-skin .btn-outline-primary:not([disabled]):not(.disabled).active,.show>.red-skin .btn-outline-primary.dropdown-toggle {
    background-color: transparent !important;
    border-color: #a60000 !important;
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15)
}

.red-skin .btn-outline-primary:not([disabled]):not(.disabled):active:focus,.red-skin .btn-outline-primary:not([disabled]):not(.disabled).active:focus,.show>.red-skin .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15)
}

.red-skin .btn-outline-secondary {
    color: #a60000 !important;
    background-color: transparent !important;
    border: 2px solid #a60000 !important
}

.red-skin .btn-outline-secondary:hover,.red-skin .btn-outline-secondary:focus,.red-skin .btn-outline-secondary:active,.red-skin .btn-outline-secondary:active:focus,.red-skin .btn-outline-secondary.active {
    color: #a60000 !important;
    background-color: transparent !important;
    border-color: #a60000 !important
}

.red-skin .btn-outline-secondary:not([disabled]):not(.disabled):active,.red-skin .btn-outline-secondary:not([disabled]):not(.disabled).active,.show>.red-skin .btn-outline-secondary.dropdown-toggle {
    background-color: transparent !important;
    border-color: #a60000 !important;
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15)
}

.red-skin .btn-outline-secondary:not([disabled]):not(.disabled):active:focus,.red-skin .btn-outline-secondary:not([disabled]):not(.disabled).active:focus,.show>.red-skin .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15)
}

.red-skin .btn-outline-default {
    color: #a60000 !important;
    background-color: transparent !important;
    border: 2px solid #a60000 !important
}

.red-skin .btn-outline-default:hover,.red-skin .btn-outline-default:focus,.red-skin .btn-outline-default:active,.red-skin .btn-outline-default:active:focus,.red-skin .btn-outline-default.active {
    color: #a60000 !important;
    background-color: transparent !important;
    border-color: #a60000 !important
}

.red-skin .btn-outline-default:not([disabled]):not(.disabled):active,.red-skin .btn-outline-default:not([disabled]):not(.disabled).active,.show>.red-skin .btn-outline-default.dropdown-toggle {
    background-color: transparent !important;
    border-color: #a60000 !important;
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15)
}

.red-skin .btn-outline-default:not([disabled]):not(.disabled):active:focus,.red-skin .btn-outline-default:not([disabled]):not(.disabled).active:focus,.show>.red-skin .btn-outline-default.dropdown-toggle:focus {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15)
}

.red-skin .card .btn-action {
    background: #a60000
}

.red-skin .card .btn-action:hover,.red-skin .card .btn-action:focus {
    background-color: #a60000 !important
}

.red-skin .card .btn-action.active {
    background-color: #ccc !important
}

.red-skin .md-outline input[type="text"]:focus:not([readonly]),.red-skin .md-outline input[type="password"]:focus:not([readonly]),.red-skin .md-outline input[type="email"]:focus:not([readonly]),.red-skin .md-outline input[type="url"]:focus:not([readonly]),.red-skin .md-outline input[type="time"]:focus:not([readonly]),.red-skin .md-outline input[type="date"]:focus:not([readonly]),.red-skin .md-outline input[type="datetime-local"]:focus:not([readonly]),.red-skin .md-outline input[type="tel"]:focus:not([readonly]),.red-skin .md-outline input[type="number"]:focus:not([readonly]),.red-skin .md-outline input[type="search-md"]:focus:not([readonly]),.red-skin .md-outline input[type="search"]:focus:not([readonly]),.red-skin .md-outline textarea:focus:not([readonly]) {
    border-color: #a60000;
    box-shadow: inset 0 0 0 1px #a60000
}

.red-skin .md-outline input[type="text"]:focus:not([readonly])+label,.red-skin .md-outline input[type="password"]:focus:not([readonly])+label,.red-skin .md-outline input[type="email"]:focus:not([readonly])+label,.red-skin .md-outline input[type="url"]:focus:not([readonly])+label,.red-skin .md-outline input[type="time"]:focus:not([readonly])+label,.red-skin .md-outline input[type="date"]:focus:not([readonly])+label,.red-skin .md-outline input[type="datetime-local"]:focus:not([readonly])+label,.red-skin .md-outline input[type="tel"]:focus:not([readonly])+label,.red-skin .md-outline input[type="number"]:focus:not([readonly])+label,.red-skin .md-outline input[type="search-md"]:focus:not([readonly])+label,.red-skin .md-outline input[type="search"]:focus:not([readonly])+label,.red-skin .md-outline textarea:focus:not([readonly])+label {
    color: #a60000
}

.red-skin .md-bg input[type="text"],.red-skin .md-bg input[type="password"],.red-skin .md-bg input[type="email"],.red-skin .md-bg input[type="url"],.red-skin .md-bg input[type="time"],.red-skin .md-bg input[type="date"],.red-skin .md-bg input[type="datetime-local"],.red-skin .md-bg input[type="tel"],.red-skin .md-bg input[type="number"],.red-skin .md-bg input[type="search-md"],.red-skin .md-bg input[type="search"],.red-skin .md-bg textarea.md-textarea {
    background-image: linear-gradient(to bottom, #a60000, #a60000),linear-gradient(to bottom, #ced4da, #ced4da)
}

.red-skin input[type="email"]:focus:not([readonly]),.red-skin input[type="text"]:focus:not([readonly]),.red-skin input[type="password"]:focus:not([readonly]),.red-skin input[type="number"]:focus:not([readonly]),.red-skin textarea.md-textarea:focus:not([readonly]) {
    border-color: #a60000;
    box-shadow: 0 1px 0 0 #a60000
}

.red-skin input[type="email"]:focus:not([readonly])+label,.red-skin input[type="text"]:focus:not([readonly])+label,.red-skin input[type="password"]:focus:not([readonly])+label,.red-skin input[type="number"]:focus:not([readonly])+label,.red-skin textarea.md-textarea:focus:not([readonly])+label {
    color: #a60000
}

.red-skin input[type="checkbox"]:checked+label:before {
    border-right: 2px solid #a60000;
    border-bottom: 2px solid #a60000
}

.red-skin input[type="checkbox"].filled-in:checked+label:before {
    border-right: 2px solid #a60000;
    border-bottom: 2px solid #a60000
}

.red-skin input[type="checkbox"].filled-in:checked+label:after {
    background-color: #a60000;
    border-color: #a60000
}

.red-skin .md-form .prefix.active {
    color: #a60000
}

.red-skin .select-wrapper.colorful-select.md-form.md-outline span.caret.active {
    color: #a60000 !important
}

.red-skin .select-wrapper.colorful-select.md-form.md-outline input.select-dropdown:focus {
    border-color: #a60000;
    box-shadow: inset 0 0 0 1px #a60000
}

.red-skin .select-wrapper.colorful-select.md-form.md-outline+label.active {
    color: #a60000
}

.red-skin .select-wrapper.colorful-select.md-form .dropdown-content li.active,.red-skin .select-wrapper.colorful-select.md-form .dropdown-content li a,.red-skin .select-wrapper.colorful-select.md-form .dropdown-content li span:hover {
    background-color: #a60000 !important
}

.red-skin .select-wrapper.colorful-select.md-form .dropdown-content li.disabled.active {
    background-color: transparent !important
}

.red-skin .top-nav-collapse {
    background-color: #a60000
}

.red-skin .carousel-multi-item .controls-top>a,.red-skin .carousel-multi-item .carousel-indicators li,.red-skin .carousel-multi-item .carousel-indicators li.active {
    background-color: #a60000
}

.red-skin .form-header,.red-skin .card-header {
    background-color: #a60000
}

.red-skin .spinner-primary-color,.red-skin .spinner-primary-color-only {
    border-color: #a60000
}

.red-skin .pagination-primary-color .page-item.active .page-link,.red-skin .pagination-primary-color .page-item.active .page-link:focus,.red-skin .pagination-primary-color .page-item.active .page-link:hover {
    color: #a60000;
    background-color: #a60000
}

.red-skin .pagination-primary-color .page-link {
    color: #a60000
}

.red-skin .pagination-primary-color .page-link:focus {
    box-shadow: none
}
